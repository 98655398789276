// material-ui
import { useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';
// import { Typography } from '../../../../../node_modules/@mui/material/index';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { useEffect } from 'react';
// import axios from 'axios';
// import { useState } from 'react';
import Logo from 'components/Logo/Logo';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  // const [region, setRegion] = useState([]);
  // useEffect(() => {
  //   let config = {
  //     method: 'get',
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_CORE_HOST}/regions`,
  //     headers: {
  //       Authorization: localStorage.getItem('ACCESS_TOKEN')
  //     }
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       setRegion(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <>
      <div style={{ paddingTop: '1em', width: '80px' }}>{<Logo />}</div>
      <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingRight: '2em' }}>
        {/* <Typography sx={{ p: '10%', color: '#000000' }}>Region</Typography>
        <FormControl sx={{ minWidth: 100, width: '15em' }}>
          <Select
            displayEmpty
            sx={{
              borderRadius: 3,
              color: '#000000'
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            defaultValue={'all'}
          >
            <MenuItem value="all">All</MenuItem> */}
        {/* {region.map((item) => {
              return (
                <MenuItem value={item.name} key={item.id}>
                  {item.name}
                </MenuItem>
              );
            })} */}
        {/* </Select>
        </FormControl> */}
        {/* <Notification /> */}
        {!matchesXs && <Profile />}
        {matchesXs && <MobileSection />}
      </div>
    </>
  );
};

export default HeaderContent;

import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumbs, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { useFormik } from '../../../node_modules/formik/dist/index';
import { Link, useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import axios from '../../../node_modules/axios/index';
import { styled } from '@mui/material/styles';
import { Avatar, Dialog, DialogContent, InputAdornment, MenuItem, Select } from '../../../node_modules/@mui/material/index';

const ColorButton = styled(Button)(() => ({
  color: '#000000',
  backgroundColor: 'gray',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#ff0000',
    borderRadius: '8px'
  }
}));

const BackButton = styled(Button)(() => ({
  color: '#000000',
  backgroundColor: 'silver',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'gray',
    borderRadius: '8px'
  }
}));

const AddUser = () => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const navigate = useNavigate();
  const [roles, setRoles] = useState();
  const [password, setPassword] = useState();
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
    setSuccessOpen(false);
    // window.location.reload(false);
  };

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/roles`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data.data));
        setRoles(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [accessToken, navigate]);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      fullName: '',
      email: '',
      contactNumber: '',
      password: password ? password : '',
      roleId: ''
    },

    onSubmit: async (values) => {
      const { fullName, email, password, contactNumber, roleId } = values;
      if (selectedFile) {
        const formData = new FormData();
        formData.append('uri', selectedFile, selectedFile.name);
        let config1 = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/public-uploads`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: formData
        };
        axios
          .request(config1)
          .then((response) => {
            const profileImage = response.data.filePath;
            console.log('confif1', response.data.filePath);
            if (profileImage) {
              let config2 = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_CORE_HOST}/users`,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`
                },
                data: {
                  email: email,
                  password: password,
                  fullName: fullName,
                  profileImage: profileImage,
                  phoneNumber: contactNumber
                }
              };

              axios
                .request(config2)
                .then((response) => {
                  const userData = response.data;
                  console.log('config2', response.data);
                  let config3 = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_CORE_HOST}/user-roles`,
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${accessToken}`
                    },
                    data: {
                      userId: userData.id,
                      roleId: roleId
                    }
                  };

                  axios
                    .request(config3)
                    .then((response) => {
                      console.log(JSON.stringify(response.data));
                      setSuccessOpen(true);
                    })
                    .catch((error) => {
                      console.log(error);
                      setErrorMessage('Sorry an error occurred. Please try again');
                      setErrorOpen(true);
                    });
                })
                .catch((error) => {
                  console.log(error);
                  setErrorMessage('Sorry an error occurred. Please try again');
                  setErrorOpen(true);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage('Sorry an error occurred. Please try again');
            setErrorOpen(true);
          });
      } else {
        let config4 = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/users`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            email: email,
            password: password,
            fullName: fullName,
            phoneNumber: contactNumber
          }
        };

        axios
          .request(config4)
          .then((response) => {
            const userData = response.data;
            let config5 = {
              method: 'post',
              maxBodyLength: Infinity,
              url: `${process.env.REACT_APP_CORE_HOST}/user-roles`,
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
              },
              data: {
                userId: userData.id,
                roleId: roleId
              }
            };

            axios
              .request(config5)
              .then((response) => {
                console.log(JSON.stringify(response.data));
                setSuccessOpen(true);
              })
              .catch((error) => {
                console.log(error);
                setErrorMessage('Sorry an error occurred. Please try again');
                setErrorOpen(true);
              });
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage('Sorry an error occurred. Please try again');
            setErrorOpen(true);
          });
      }
    }
  });

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const generateAutoPassword = () => {
    let charset = '!@#$%^&*()0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let newPassword = '';

    for (let i = 0; i < 8; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    formik.values.password = newPassword;
    console.log('password', newPassword);
    console.log('formik', formik.values.password);
    setPassword(newPassword);
  };

  const dialogOpen = () => {
    setOpen(true);
  };
  const dialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Page title */}
      <Grid sx={{ mt: '5%' }}>
        <Typography variant="h3" color="#b71c1c" align="left" mt="-5%">
          Add User
        </Typography>
      </Grid>
      {/* Breadcrumbs */}
      <Grid sx={{ mt: '1%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={'/users'} style={{ textDecoration: 'none' }}>
            Users
          </Link>
          <Typography style={{ textDecoration: 'none' }}>Add User</Typography>
        </Breadcrumbs>
      </Grid>
      {/* Form */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container directon="column" sx={{ mt: '1%', backgroundColor: '#FFFF' }}>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Full Name</Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Email<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                type="text"
                fullWidth
                placeholder="Full Name"
                name="fullName"
                variant="outlined"
                value={formik.values.fullName}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                required
                type="email"
                fullWidth
                placeholder="Email"
                name="email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Profile Image</Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Password<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                type="file"
                fullWidth
                placeholder="Thumbnail"
                name="profileImage"
                variant="outlined"
                // value={formik.values.thumbnail}
                onChange={changeHandler}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{file && <Avatar onClick={dialogOpen} src={file} variant="square" />}</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                required
                type="text"
                fullWidth
                placeholder="Password"
                name="password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} md={12}>
            <Grid xs={6} md={6}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5}>
              <ColorButton onClick={generateAutoPassword}>
                <Typography variant="h6" color="black" align="center">
                  Auto generate
                </Typography>
              </ColorButton>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Contact Number<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Role<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                required
                type="text"
                fullWidth
                placeholder="Contact Number"
                name="contactNumber"
                variant="outlined"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Select
                required
                labelId="demo-simple-select-label"
                fullWidth
                id="demo-simple-select"
                value={formik.values.roleId}
                name="roleId"
                onChange={formik.handleChange}
              >
                {roles &&
                  roles.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
          {/* Action Buttons */}
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <ColorButton type="submit" onClick={() => console.log('# Save User')}>
              <Typography variant="h4" color="black" align="center">
                SAVE
              </Typography>
            </ColorButton>
            &nbsp;
            <BackButton>
              <Link to={'/users'} style={{ color: 'inherit', textDecoration: 'none' }}>
                <Typography variant="h4" color="black" align="center">
                  CANCEL
                </Typography>
              </Link>
            </BackButton>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={errorOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {'Sorry an error occurred. Please try again.'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="success">
          {'User created successfully'}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={dialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogContent>
          <div>
            <img src={file} style={{ height: '100%', width: '100%' }} alt="Checklist" />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUser;

import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton
} from '../../../node_modules/@mui/material/index';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import axios from 'axios';
import { useFormik } from '../../../node_modules/formik/dist/index';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const pushNotifications = { inputProps: { 'aria-label': 'Push Notifications' } };
const emailNotifications = { inputProps: { 'aria-label': 'Email Notifications' } };

const ColorButton = styled(Button)(() => ({
  // color: theme.palette.getContrastText('#nnnnnn'),
  backgroundColor: '#484948',
  color: '#FFF',
  // width: '98%',
  height: '50%',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#b71c1c',
    borderRadius: '8px'
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const IsuzuSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#FF0000' : '#FF0000',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

export const SettingsNotifications = (props) => {
  console.log(props);

  const [value, setValue] = React.useState(0);
  const [profileDetails, setProfileDetails] = useState([]);
  const [successopen, setSuccessOpen] = React.useState(false);
  const [successMessage, setSucessMessasge] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessasge] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [shownewPassword, setShowNewPassword] = React.useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = React.useState(false);

  const userId = localStorage.getItem('USER_ID');

  const navigate = useNavigate();

  const accessToken = localStorage.getItem('ACCESS_TOKEN');

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/users/${userId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    try {
      axios.request(config).then((response) => {
        const data = response.data;
        setProfileDetails(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [accessToken, navigate, userId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    onSubmit: async (values, { resetForm }) => {
      const { oldPassword, newPassword, confirmPassword } = values;
      const email = profileDetails[0].email;
      if (!oldPassword) {
        setOpen(true);
        setErrorMessasge('Old password required');
      }
      if (!newPassword) {
        setOpen(true);
        setErrorMessasge('New password required');
      }
      if (!confirmPassword) {
        setOpen(true);
        setErrorMessasge('Confirm password required');
      }
      if (newPassword === confirmPassword) {
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/auth-management`,
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            action: 'passwordChange',
            value: {
              user: {
                email: email
              },
              oldPassword: oldPassword,
              password: newPassword
            }
          }
        };
        axios
          .request(config)
          .then((response) => {
            if (response.status === 201) {
              setSuccessOpen(true);
              setSucessMessasge('succesfully changed');
              resetForm({ values: '' });
              navigate('/settings');
            }
          })

          .catch((error) => {
            console.log('error', error);
            setOpen(true);
            setErrorMessasge('Sorry an error occurred. Please try again.');
          });
      } else {
        console.log('pop up message');
        setOpen(true);
        setErrorMessasge('New password and Confirm password must same');
      }
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setSuccessOpen(false);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {/* Form */}
      <Grid container directon="column" sx={{ mt: '1%', backgroundColor: '#FFFF' }}>
        <Box sx={{ borderColor: 'divider', borderBottom: 1 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderColor: 'divider' }}
            textColor="#222"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#F00'
              }
            }}
          >
            <Tab label="My Profile" />
            <Tab label="Change Password" />
            <Tab label="Notifications" />
          </Tabs>
        </Box>
        {/* {profileDetails &&
          profileDetails.map((item) => ( */}
        <TabPanel value={value} index={0} xs={12} md={12} spacing={6} style={{ width: '80%' }}>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Avatar sx={{}} alt="Remy Sharp" src="/user.jpg">
              {profileDetails && profileDetails.email && profileDetails.email.charAt(0).toUpperCase()}
            </Avatar>
          </Grid>
          <br />
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">First Name: </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Email Address: </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5}>
              <TextField
                type="text"
                fullWidth
                placeholder="First Name"
                name="name"
                variant="outlined"
                value={profileDetails.fullName}
                disabled
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000'
                  }
                }}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5}>
              <TextField
                type="text"
                fullWidth
                placeholder="Email Address "
                name="name"
                variant="outlined"
                value={profileDetails.email}
                disabled
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000'
                  }
                }}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Phone Number: </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Join Date: </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5}>
              <TextField
                type="text"
                fullWidth
                placeholder="Phone Number:"
                name="name"
                variant="outlined"
                value={profileDetails.phoneNumber}
                disabled
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000'
                  }
                }}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5}>
              <TextField
                type="text"
                fullWidth
                placeholder="Join Date"
                name="name"
                variant="outlined"
                value={profileDetails && profileDetails.createdAt && profileDetails.createdAt.slice(0, 10)}
                disabled
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000'
                  }
                }}
              />
            </Grid>
          </Grid>
        </TabPanel>
        {/* ))} */}

        <TabPanel value={value} index={1} xs={12} md={12} spacing={6} style={{ width: '80%' }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
              <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                  <Typography variant="h5">
                    Old Password<sup style={{ color: 'red' }}>*</sup>
                  </Typography>
                </Grid>
                <Grid xs={1} md={1}>
                  &nbsp;
                </Grid>
                <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                  <Typography variant="h5">
                    New Password<sup style={{ color: 'red' }}>*</sup>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                <Grid xs={5} md={5}>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required
                    placeholder="Old Password"
                    name="oldPassword"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.oldPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ color: 'grey' }}
                          >
                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid xs={1} md={1}>
                  &nbsp;
                </Grid>
                <Grid xs={5} md={5}>
                  <TextField
                    type={shownewPassword ? 'text' : 'password'}
                    required
                    id="outlined-required"
                    fullWidth
                    placeholder="New Password"
                    name="newPassword"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.newPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownNewPassword}
                            sx={{ color: 'grey' }}
                          >
                            {shownewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
                  <Typography variant="h5">
                    Confirm Password<sup style={{ color: 'red' }}>*</sup>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                <Grid xs={5} md={5}>
                  <TextField
                    type={showconfirmPassword ? 'text' : 'password'}
                    fullWidth
                    required
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    variant="outlined"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            sx={{ color: 'grey' }}
                          >
                            {showconfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
                <Grid xs={5} md={5}>
                  <ColorButton type="submit" onClick={() => console.log('# change password')}>
                    <Typography variant="h4" color="black" align="center">
                      SAVE
                    </Typography>
                  </ColorButton>
                  {/* &nbsp; */}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </TabPanel>

        <TabPanel value={value} index={2} xs={12} md={12} spacing={6} style={{ width: '80%' }}>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={12} md={12}>
              <Typography variant="h4">Track your checklists</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={12} md={12}>
              <Typography variant="h5">Never miss any upcoming updates from Isuzu</Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Push Notifications: </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Email Notifications: </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5}>
              <Typography>
                OFF <IsuzuSwitch {...pushNotifications} defaultChecked disabled /> ON
              </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5}>
              <Typography>
                OFF <IsuzuSwitch {...emailNotifications} defaultChecked /> ON
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={successopen}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Alert onClose={handleClose} severity="success">
            {successMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};

import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Countries from 'pages/components-overview/countries';
import Users from 'pages/components-overview/users';
import Language from 'pages/components-overview/language';
import Settings from 'pages/components-overview/settings';
import UpdateUsers from 'pages/users/UpdateUsers';
import UpdateLanguage from 'pages/Language/UpdateLanguage';
import AddUsers from 'pages/users/AddUsers';
import AddLanguages from 'pages/Language/AddLanguage';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Checklist = Loadable(lazy(() => import('pages/components-overview/checklist')));
const Staffs = Loadable(lazy(() => import('pages/components-overview/Staffs')));
const UpdateChecklist = Loadable(lazy(() => import('pages/checklist/UpdateChecklist')));
const UpdateStaffs = Loadable(lazy(() => import('pages/Staffs/UpdateStaffs')));
const AddStaff = Loadable(lazy(() => import('pages/Staffs/AddStaffs')));
const UpdateCountries = Loadable(lazy(() => import('pages/countries/UpdateCountry')));
const AddCountryUpdateRegion = Loadable(lazy(() => import('pages/countries/AddCountryUpdateRegion')));
const UpdateCountryUpdateRegion = Loadable(lazy(() => import('pages/countries/UpdateCountryUpdateRegion')));
const AddChecklist = Loadable(lazy(() => import('pages/checklist/AddChecklist')));
const AddCountries = Loadable(lazy(() => import('pages/countries/AddCountry')));
const Notification = Loadable(lazy(() => import('pages/components-overview/Notification')));
const Regions = Loadable(lazy(() => import('pages/components-overview/Regions')));
const AddRegions = Loadable(lazy(() => import('pages/regions/AddRegion')));
const UpdateRegions = Loadable(lazy(() => import('pages/regions/UpdateRegions')));
const Dealers = Loadable(lazy(() => import('pages/components-overview/dealers')));
const AddDealers = Loadable(lazy(() => import('pages/Dealers/AddDealers')));
const UpdateDealers = Loadable(lazy(() => import('pages/Dealers/UpdateDealers')));
const Branches = Loadable(lazy(() => import('pages/components-overview/branch')));
const AddBranches = Loadable(lazy(() => import('pages/Branch/AddBranch')));
const UpdateBranches = Loadable(lazy(() => import('pages/Branch/UpdateBranch')));
const AddBranchesUpdateDealer = Loadable(lazy(() => import('pages/Branch/AddBranchUpdateDealer')));
const UpdateBranchesUpdatedealer = Loadable(lazy(() => import('pages/Branch/UpdateBranchUpdateDealer')));
const Departments = Loadable(lazy(() => import('pages/components-overview/department')));
const AddDepartments = Loadable(lazy(() => import('pages/Departments/AddDepartment')));
const UpdateDepartment = Loadable(lazy(() => import('pages/Departments/UpdateDepartment')));
const ChangePassword = Loadable(lazy(() => import('pages/components-overview/ChangePassword')));
const AddQuestion = Loadable(lazy(() => import('pages/checklist/AddChecklistTabs/AddQuestion')));
const AddUpdateQuestion = Loadable(lazy(() => import('pages/checklist/UpdateChecklistTabs/AddQuestion')));
const Review = Loadable(lazy(() => import('pages/checklist/UpdateChecklistTabs/Review')));
const UpdateQuestionAddChecklist = Loadable(lazy(() => import('pages/checklist/AddChecklistTabs/EditQuestion')));
const UpdateQuestionUpdateChecklist = Loadable(lazy(() => import('pages/checklist/UpdateChecklistTabs/EditQuestion')));
const AddChecklistAddCategory = Loadable(lazy(() => import('pages/checklist/AddChecklistTabs/CategoryManagement/AddCategory')));
const AddChecklistUpdateCategory = Loadable(lazy(() => import('pages/checklist/AddChecklistTabs/CategoryManagement/UpdateCategory')));
const UpdateChecklistAddCategory = Loadable(lazy(() => import('pages/checklist/UpdateChecklistTabs/CategoryManagement/AddCategory')));
const UpdateChecklistUpdateCategory = Loadable(lazy(() => import('pages/checklist/UpdateChecklistTabs/CategoryManagement/UpdateCategory')));
const Videos = Loadable(lazy(() => import('pages/components-overview/videos')));
const News = Loadable(lazy(() => import('pages/components-overview/news')));
const Flyers = Loadable(lazy(() => import('pages/components-overview/flyers')));
const AddVideos = Loadable(lazy(() => import('pages/videos/AddVideo')));
const UpdateVideo = Loadable(lazy(() => import('pages/videos/UpdateVideo')));
const AddNews = Loadable(lazy(() => import('pages/news/AddNews')));
const UpdateNews = Loadable(lazy(() => import('pages/news/UpdateNews')));
const AddFlyers = Loadable(lazy(() => import('pages/flyers/AddFlyer')));
const UpdateFlyer = Loadable(lazy(() => import('pages/flyers/UpdateFlyer')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'checklists',
      element: <Checklist />
    },
    {
      path: 'staffs',
      element: <Staffs />
    },
    {
      path: 'countries',
      element: <Countries />
    },
    {
      path: 'users',
      element: <Users />
    },
    {
      path: 'languages',
      element: <Language />
    },
    {
      path: 'settings',
      element: <Settings />
    },
    {
      path: 'checklists/updatechecklist/:id',
      element: <UpdateChecklist />
    },
    {
      path: 'checklists/addchecklist',
      element: <AddChecklist />
    },
    {
      path: 'checklists/addchecklist/addquestion',
      element: <AddQuestion />
    },
    {
      path: 'checklists/addchecklist/updatequestion/:id',
      element: <UpdateQuestionAddChecklist />
    },
    {
      path: 'users/updateusers/:id',
      element: <UpdateUsers />
    },
    {
      path: 'languages/updatelanguage/:id',
      element: <UpdateLanguage />
    },
    {
      path: 'staffs/updatestaffs/:id',
      element: <UpdateStaffs />
    },
    {
      path: 'regions/addregion/updatecountry/:id',
      element: <UpdateCountries />
    },
    {
      path: 'staffs/addstaff',
      element: <AddStaff />
    },
    {
      path: 'regions/addregion/addcountry',
      element: <AddCountries />
    },
    {
      path: 'users/addusers',
      element: <AddUsers />
    },
    {
      path: 'languages/addlanguages',
      element: <AddLanguages />
    },
    {
      path: 'notification',
      element: <Notification />
    },
    {
      path: 'regions',
      element: <Regions />
    },
    {
      path: 'regions/addregion',
      element: <AddRegions />
    },
    {
      path: 'regions/updateregion/:id',
      element: <UpdateRegions />
    },
    {
      path: 'dealers',
      element: <Dealers />
    },
    {
      path: 'dealers/addDealers',
      element: <AddDealers />
    },
    {
      path: 'dealers/updateDealers/:id',
      element: <UpdateDealers />
    },
    {
      path: 'branches',
      element: <Branches />
    },
    {
      path: 'dealers/addDealers/addbranch',
      element: <AddBranches />
    },
    {
      path: 'dealers/addDealers/updatebranch/:id',
      element: <UpdateBranches />
    },
    {
      path: 'dealers/updateDealers/:id/addbranch',
      element: <AddBranchesUpdateDealer />
    },
    {
      path: 'dealers/updateDealers/:id/updatebranch/:id',
      element: <UpdateBranchesUpdatedealer />
    },
    {
      path: 'departments',
      element: <Departments />
    },
    {
      path: 'departments/adddepartment',
      element: <AddDepartments />
    },
    {
      path: 'departments/updatedepartment/:id',
      element: <UpdateDepartment />
    },
    {
      path: 'checklists/updatechecklist/:id/addquestion',
      element: <AddUpdateQuestion />
    },
    {
      path: 'checklists/updatechecklist/:id/updatequestion/:id',
      element: <UpdateQuestionUpdateChecklist />
    },
    {
      path: 'changePassword',
      element: <ChangePassword />
    },
    {
      path: 'checklists/updatechecklist/:id/checklistreview/:id',
      element: <Review />
    },
    {
      path: 'regions/updateregion/:id/addcountry',
      element: <AddCountryUpdateRegion />
    },
    {
      path: 'regions/updateregion/:id/updatecountry/:id',
      element: <UpdateCountryUpdateRegion />
    },
    {
      path: 'checklists/addchecklist/addCategory',
      element: <AddChecklistAddCategory />
    },
    {
      path: 'checklists/addchecklist/updatecategory/:id',
      element: <AddChecklistUpdateCategory />
    },
    {
      path: 'checklists/updatechecklist/:id/addCategory',
      element: <UpdateChecklistAddCategory />
    },
    {
      path: 'checklists/updatechecklist/:id/updatecategory/:id',
      element: <UpdateChecklistUpdateCategory />
    },
    {
      path: 'videos',
      element: <Videos />
    },
    {
      path: 'news',
      element: <News />
    },
    {
      path: 'flyers',
      element: <Flyers />
    },
    {
      path: 'videos/addvideos',
      element: <AddVideos />
    },
    {
      path: 'videos/updatevideo/:id',
      element: <UpdateVideo />
    },
    {
      path: 'news/addnews',
      element: <AddNews />
    },
    {
      path: 'news/updatenews/:id',
      element: <UpdateNews />
    },
    {
      path: 'flyers/addflyers',
      element: <AddFlyers />
    },
    {
      path: 'flyers/updateflyer/:id',
      element: <UpdateFlyer />
    }
  ]
};

export default MainRoutes;
